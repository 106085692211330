.card {
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  font-family: 'Futura', sans-serif;
}

.card-header {
  background-color: white;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-body {
  //background-color: white;
  font-size: 1em;
  color: #333;
}

.card-button {
  //background: #195185;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.button-card-body {
  background-color: rgba(2, 15, 26, 0.32);
  border-radius: 40px;
  padding: 20px;
  display: grid;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  font-size: 1em;
  width: 50%;
  height: 90%;
  color: white;
}

.chronograf-logo-card {
  background-image: url('../../asset/images/chronograf.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  z-index: 100;
  justify-self: center;
  animation: spin 4s linear infinite;
  animation-play-state: paused;
}

.chronograf-logo-card:hover {
  animation: spin 4s linear infinite; // Animazione costante quando il mouse passa sopra
}

.button-card-body:hover {
  background-color: rgba(2, 15, 26, 0.42);
  .chronograf-logo-card {
    animation-play-state: running;
  }
}
.card-button {
  background-color: #0073e6;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.card-button:hover {
  background-color: #005bb5;
  transform: scale(1.05);
}
