.footer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;
}

.footer-logos {
    display: grid;
    grid-template-columns: 1fr minmax(200px, 200px) 1fr;
    gap: 40px;
    width: 100%;
    margin-bottom: 20px;
}

.footer-samothrace-logo{
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-self: center;
    justify-self: center;
}

.footer-alfameg-logo {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-self: center;
    justify-self: left;
}

.footer-unict-logo {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-self: center;
    justify-self: right;
}