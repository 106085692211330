@import 'src/style/mixins';
@import 'src/style/influx-colors';

$ix-text-font: 'Roboto', Helvetica, Arial, Tahoma, Verdana, sans-serif;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  Fira Sans,
  Droid Sans,
  Helvetica Neue,
  sans-serif;
}

.mainContainer {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // @include custom-scrollbar($g3-castle, $c-pool);
  @include gradient-v($g3-castle, $g0-obsidian);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContainerHome {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // @include custom-scrollbar($g3-castle, $c-pool);
  @include gradient-v($g3-castle, $g0-obsidian);
  flex-direction: column;
  display: flex;
  align-items: center;
}

.auth-box {
  z-index: 90;
  margin-top: 10px;
}

.layout-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentContainer {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  z-index: 100;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  color: white;
  margin-bottom: 10px;
  align-items: flex-start;
}

.auth-image {
  background-image: url('../../asset/images/auth-bg.svg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.white-line {
  background-image: url('../../asset/images/line.svg');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -6rem;
  left: -5rem;
  z-index: 2;
}

.auth-logo {
  background-image: url('../../asset/images/logo_samothrace_notext.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 237px;
  height: 150px;
  z-index: 100;
  margin-right: 50px;
  right: 8%;
  position: relative;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inputContainer > .errorLabel {
  color: red;
  font-size: 12px;
}

.inputBox {
  height: 40px;
  width: 100%;
  font-size: medium;
  border-bottom: 2px solid white;
  border-right: none;
  border-left: none;
  border-top: none;
  z-index: 10;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  color: white;
}

.inputBox:hover::placeholder {
  color: #0073e6;
}

.inputContainer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.inputContainer:hover::after {
  transform: scaleX(1);
  background-color: #0073e6;
  z-index: 100;
}

input[type='button'] {
  border: none;
  background: #195185;
  color: white;
  padding: 10px 20px;
  margin: 8px;
  font-size: 20px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 100;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

input[type='button']:hover {
  background-color: #005bb5;
  transform: scale(1.05);
}

.footer-unict-logo {
  background-image: url('../../asset/images/UniCT-Logo-Bianco.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  z-index: 100;
  margin-top: 50px;
  top: 8%;
  position: relative;
}

.footer-alfameg-logo {
  background-image: url('../../asset/images/LogoAlfamegWhite.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 200px;
  height: 49px;
  z-index: 100;
  margin-top: 50px;
  top: 8%;
  position: relative;
}

.footer-samothrace-logo {
  background-image: url('../../asset/images/logo_samothrace_notext.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 175px;
  height: 100px;
  z-index: 100;
  margin-top: 50px;
  top: 8%;
  position: relative;
  align-self: center;
}

.chronograf-logo {
  background-image: url('../../asset/images/chronograf.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  z-index: 100;
  margin-top: 50px;
  top: 8%;
  position: relative;
  justify-content: center;
  animation: spin 4s linear infinite;
  animation-play-state: paused;
}

.chronograf-logo:hover {
  animation: spin 4s linear infinite; // Animazione costante quando il mouse passa sopra
}

.card {
  width: 200px;
  height: 300px;
  padding: 20px;
  background: white;
  border-radius: 40px;
  box-shadow: 0 4px 8px rgba(5, 2, 8, 8);
  margin: 20px;
  font-family: 'Futura', sans-serif;
}

.card-header {
  background-color: white;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-body {
  background-color: transparent;
  display: flex;
  align-self: center;
  justify-self: center;
  font-size: 1em;
  gap: 40px;
  color: #333;
}

.card-button {
  background: #195185;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-family: inherit;
  font-size: large;
}

.header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Futura', sans-serif;
  color: white;
}

.header-title {
  font-size: 1.5em;
  font-weight: bold;
}

.logout-button {
  border: none;
  background: #195185;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 100;
}

.logout-button:hover {
  background: #005bb5;
  transform: scale(1.05);
}

.card-button {
  //background: #195185;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 15px 0;
}

.card-buttons {
  background: transparent;
}

.large-button {
  background: #651b06;
  color: white;
  padding: 20px 30px;
  font-size: 1.5em;
  width: 300px;
  height: 100px;
  border-radius: 25px;
}

.hover-text {
  margin-top: 40px;
  margin-left: 40px;
  nargin-right: 20px;
  width: 100%;
  text-align: justify;
  font-size: 1.4rem;
  color: #555;
}
