.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 1fr 10%;
  grid-template-areas:
  "header header header"
  "content content content"
  "footer footer footer";
}

.grid-container-users-list {
  justify-self: center;
  height: 100%;
  width: 65%;
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 1fr 10%;
}

.container-users-list {
  background: #1F2039;
  opacity: 30%;
  border-radius: 4rem;
  border: solid white 1px;
  z-index: 3;
  margin-top: 3%;

  box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.8), /* Ombra interna scura */
  inset -4px -4px 8px rgba(255, 255, 255, 0.05), /* Ombra interna chiara */
  4px 4px 15px rgba(0, 0, 0, 0.7),  /* Ombra esterna scura */
  -4px -4px 15px rgba(255, 255, 255, 0.1); /* Ombra esterna chiara */

}

.container-title {
  color: white;
  font-size: 1.5rem;
  justify-self: center;
  align-self: center;
}