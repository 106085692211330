@import 'influx-colors';

// Gradients
@mixin gradient-v($startColor, $endColor) {
  background: $startColor;
  background: -moz-linear-gradient(top,  $startColor 0%, $endColor 100%);
  background: -webkit-linear-gradient(top,  $startColor 0%,$endColor 100%);
  background: linear-gradient(to bottom,  $startColor 0%,$endColor 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startColor', endColorstr='$endColor',GradientType=0 );
}
@mixin gradient-h($startColor, $endColor) {
  background: $startColor;
  background: -moz-linear-gradient(left,  $startColor 0%, $endColor 100%);
  background: -webkit-linear-gradient(left,  $startColor 0%,$endColor 100%);
  background: linear-gradient(to right,  $startColor 0%,$endColor 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startColor', endColorstr='$endColor',GradientType=1 );
}
@mixin gradient-diag-up($startColor, $endColor) {
  background: $startColor;
  background: -moz-linear-gradient(45deg,  $startColor 0%, $endColor 100%);
  background: -webkit-linear-gradient(45deg,  $startColor 0%,$endColor 100%);
  background: linear-gradient(45deg,  $startColor 0%,$endColor 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startColor', endColorstr='$endColor',GradientType=1 );
}
@mixin gradient-diag-down($startColor, $endColor) {
  background: $startColor;
  background: -moz-linear-gradient(135deg,  $startColor 0%, $endColor 100%) !important;
  background: -webkit-linear-gradient(135deg,  $startColor 0%,$endColor 100%) !important;
  background: linear-gradient(135deg,  $startColor 0%,$endColor 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startColor', endColorstr='$endColor',GradientType=1 ) !important;
}
@mixin gradient-r($startColor, $endColor) {
  background: $startColor;
  background: -moz-radial-gradient(center, ellipse cover,  $startColor 0%, $endColor 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  $startColor 0%,$endColor 100%);
  background: radial-gradient(ellipse at center,  $startColor 0%,$endColor 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startColor', endColorstr='$endColor',GradientType=1 );
}

// Custom Scrollbars (Chrome Only)
$scrollbar-width: 16px;
$scrollbar-offset: 3px;
@mixin custom-scrollbar($trackColor, $handleColor) {
  &::-webkit-scrollbar {
    width: $scrollbar-width;

    &-button {
      background-color: $trackColor;
    }
    &-track {
      background-color: $trackColor;
    }
    &-track-piece {
      background-color: $trackColor;
      border: $scrollbar-offset solid $trackColor;
      border-radius: ($scrollbar-width * 0.5);
    }
    &-thumb {
      background-color: $handleColor;
      border: $scrollbar-offset solid $trackColor;
      border-radius: ($scrollbar-width * 0.5);
    }
    &-corner {
      background-color: $trackColor;
    }
  }
  &::-webkit-resizer {
    background-color: $trackColor;
  }
}
@mixin custom-scrollbar-round($trackColor, $handleColor) {
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;

    &-button {
      background-color: $trackColor;
    }
    &-track {
      background-color: $trackColor;
      border-top-right-radius: ($scrollbar-width * 0.5);
      border-top-left-radius: ($scrollbar-width * 0.5);
      border-bottom-left-radius: ($scrollbar-width * 0.5);
      border-bottom-right-radius: ($scrollbar-width * 0.5);
    }
    &-track-piece {
      background-color: $trackColor;
      border: $scrollbar-offset solid $trackColor;
      border-radius: ($scrollbar-width * 0.5);
    }
    &-thumb {
      background-color: $handleColor;
      border: $scrollbar-offset solid $trackColor;
      border-radius: ($scrollbar-width * 0.5);
    }
    &-corner {
      background-color: $trackColor;
    }
  }
  &::-webkit-resizer {
    background-color: $trackColor;
  }
}


// Block user select
@mixin no-user-select() {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  -o-user-select: none !important;
  &, &:hover {
    cursor: default;
  }
}
%no-user-select {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  -o-user-select: none !important;
  &, &:hover {
    cursor: default;
  }
}

// Shadows
%drop-shadow {
  box-shadow: 0 0 10px 2px $g2-kevlar;
}
